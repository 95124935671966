.generatedcont {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 90vh;
    margin-top: 5vh;
    padding-bottom: 5%;
}

.generatedcont .goback {
    width: 40%;
    align-self: flex-end;
    margin-bottom: 2vh;
    font-family: Circular, serif;
    text-align: center;
    color: white;
    background: #2196F3;
    padding: 1%;
    font-size: 20px;
    border-style: none;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    text-decoration: none;
    align-self: center;
}

.generatedcont p {
    width: 80%;
}


.generatedcont .goback:hover {
    background-color: white;
    color: #2196F3;
}

.generatedcont .wrapped {
    width: 40vw;
}

.generatedcont p {
    margin-bottom: 3vh;
    margin-top: 0vh;
}

.generatedcont .wrapped img {
    width: 100%;
    margin-bottom: 5vh;
}

.generatedcont p {
    visibility: hidden;
}

@media (max-device-width: 800px) {
    .generatedcont .wrapped {
        width: 90vw;
    }
    
    .generatedcont .goback {
        font-size: 20px;
    }
    .generatedcont .wrapped img {
        width: 100%;
    }
    .generatedcont p {
        visibility: visible;
    }

}


