.home {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    max-height: 100vh;
    min-height: 100vh;
}

.home .locationSec { 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 40%;
    padding: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    align-self: flex-start;
}



.home img {
    width: 40%;
    padding: 3%;
}

.home a {
    width: 50%;
}

.home .about {
    position: absolute;
    bottom: 10vh;
    left: 90vw;
    width: 10vw;
    height: 2vh;
    color: black;
    font-family: Circular, serif;
    text-decoration: none;
}


.home a img {
    display: block;
    max-width:80vw;
    max-height:50vh;
    width: auto;
    height: auto;
}

.home .locationSec .intro {
    color: black;  
    font-family: Circular, serif;
    font-size: 56px;
    width: 50%;
    font-weight: normal;
    margin-bottom: 1vh;
}


.home .locationSec .detail {
    font-family: Circular, serif;
    font-size: 24px;
    width: 90%;
    margin-bottom: 4vh;
}

.home .locationSec .detail i{
    font-family: Circular, serif;
    font-size: 14px;
    width: 90%;
    margin-bottom: 4vh;
}

.home .locationSec label {
    color: black;
    font-family: Circular, serif;
    font-size: 2.5vh;
    letter-spacing: 1px;
}

.home .locationSec input {
    background-color: #E8E8E8;
    border-color: none;
    box-shadow: none;
    outline: none;
    border-style: none;
    padding: 2%;
    width: 80%;
    font-size: 2vh;
    margin-left: 2%;
    font-family: Circular, serif;
}

.home .locationSec .searchcont {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #E8E8E8;
    padding-left: 2%;   
}

.home .locationSec .searchcont #searchicon {
    color: #696969;
    font-size: 2.5vh;

}

.home .locationSec .imagecont {
    width: 100%;
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 30vh;
}

.home .locationSec .imagecont img {
    visibility: visible;
    height: 90%;
    width: auto;
}

.home .locationSec .otherselections {
    display: flex; 
    flex-direction: row;
    justify-content: center;
    margin-top: 1vh;
    margin-bottom: 1vh;
    align-items: center;
}


.home .locationSec .otherselections label  {
    font-size: 16px;
}


.home .locationSec .otherselections input  {
    width: 20%;
}




.home .locationSec .icons  {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
    margin-top: 2vh;
    height: 17vh;
}

.home .locationSec .icons::-webkit-scrollbar { 
    width: 2px;
    background-color: white;
    border-radius: 6px;
}
.home .locationSec .icons::-webkit-scrollbar-thumb { 
    border-radius: 6px;
    background-color: black;
}

.home .locationSec .icons .location {
    display: flex;
    flex-direction: row;
    flex-wrap:nowrap;
    justify-content: space-around;
    align-items: center;
    background-color: #C4C4C4;
    border-radius: 20px;

    font-size: 20px;
    margin: 2%;
    height: 5vh;
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;

}


.home .locationSec .icons .location .locicon {
    font-size: 2.5vh;
    width: 20%;
}

.home .locationSec .icons .location .smallicon {
    color: rgba(0, 0, 0, 0.54);
    width: 20%;
    margin-left: 5%;
}

.home .locationSec .icons .location .smallicon:hover {
    color: rgb(141, 1, 1);
}

.home .locationSec .icons .location p {
    white-space: nowrap;
    font-family: Circular, serif;
    margin-right: 1%;
}

.home .locationSec .icons .location svg {
    margin: 3%;
}



.home .locationSec .gen {
    min-width: 70%;
    margin-bottom: 2vh;
    align-self: flex-end;
    margin-top: 5vh;
    font-family: Circular, serif;
    text-align: center;
    padding: 2%;
    font-size: 20px;
    border-style: none;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    align-self: center;
}

.home .locationSec .generate {
    color: white;
    background: #2196F3;
}

.home .locationSec .generate {
    
    color: white;
    background: #2196F3;
}

.home .locationSec .generate:hover{
    cursor: pointer;
    background: white;
    color: #2196F3;
}

.home .locationSec .generating {
    color: black;
}

.home .locationSec .loader {
    height: auto;
    width: 20%;
    align-self: center;
}


.home .locationSec .failed {
    min-width: 70%;
    align-self: flex-end;
    margin-top: 5vh;
    font-family: Circular, serif;
    text-align: center;
    color: white;
    background-color: red;
    padding: 2%;
    font-size: 20px;
    border-style: none;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    align-self: center;
}

.home .locationSec .failed:hover {

    background-color: white;
    color: red;

}


.home .locationSec .generated {
    
    color: rgb(7, 202, 7);
    background-color: black;
    
}

.home .locationSec .generated:hover {
    background-color: rgb(7, 202, 7);
    color: black;
}

.home .locationSec .moveBtn {
    color: black;
    background-color: transparent;
    min-width: 70%;
    font-family: Circular, serif;
    text-align: center;
    padding: 2%;
    font-size: 20px;
    border-style: none;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0), 0px 2px 2px rgba(0, 0, 0, 0);
    border-radius: 4px;
    margin-bottom: 3vh;
    align-self: center;
}


.home .locationSec .moveBtn:hover {
    color: #2196F3;
}

.home .arrow {
    font-size: 24px;
}


.home .arrow:hover {
    color: #2196F3;
}


@media (max-device-width: 800px) {
    .home .locationSec {
        width: 100%;
        height: 100vh;
        margin-top: 1vh;
    }

    .home .about {
        position: static;
        align-self: center;
    }
    


    .home .locationSec .intro {
        width: 100% !important;
    }

    .home .locationSec .detail {
        width: 100% !important;
    }
    .home img {
        visibility: hidden;
    }

    .home .locationSec .icons  {
        height: auto;
        overflow-y: visible;
    }


    .home .locationSec .icons .location {
        width: 100%;
    } 

    .home .locationSec .imagecont img {
        visibility: visible;
        width: 70%;
        height: auto;
    }
    
}

