.aboutcont {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 100vh;
    padding: 2%;
    min-height: 100vh;
}
.aboutcont .back {
    height: 2vh;
    color: black;
    font-family: Circular, serif;
    text-decoration: none;
    align-self: flex-start;
}

.aboutcont .intro {
    color: black;  
    font-family: Circular, serif;
    font-size: 40px;
    width: 50%;
    font-weight: normal;
    justify-self: flex-start;
    margin-bottom: 1vh;
}



.aboutcont .detail {
    font-family: Circular, serif;
    font-size: 24px;
    width: 90%;
    margin-bottom: 4vh;
}

.aboutcont .detail i {
    color: #2196F3;
}

.aboutcont .users {
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin-top: 4vh;
}

.aboutcont .users .profile {
    display: flex; 
    width: 30vh;
    margin-top: 2vh;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.aboutcont .users .profile:hover {
    transform: scale(1.1);
}

.aboutcont .users .profile img {
    width: 100% ;
    border-radius: 100%;
    border-color: black;
    border-width: 4px;
    border-style: solid;
    height: 100%;
}

.aboutcont .users a {
    text-decoration: none;

}

.aboutcont .users .profile p {
    margin-top: 10%;
    text-decoration: none;
    font-family: Circular,serif;
    color: black;
}




@media (max-device-width: 800px) {
    .aboutcont {
        padding: 5%;
    }
    .aboutcont .back { 
        align-self: center;
    }

    .aboutcont .intro {
        width: 100%;
    }

    .aboutcont .detail {
        font-size: 20px;
    }
}